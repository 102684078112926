import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'gatsby';
import noImage from '../images/profiles/noimage.png';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getFirstWord = (string) => {
  return string?.split(' ')[0] ?? ''; 
};

const ProfileMarquee = ({ profiles }) => {
  const shuffledProfiles = shuffleArray([...profiles]).slice(0, 20);

  return (
    <Marquee speed={50} gradient={false}>
      {shuffledProfiles.map((profile, index) => (
        <Link to={profile.fields.slug}>
          <div key={index} className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-slate-700 hover:bg-slate-600 shadow-lg text-center mx-5 md:w-64">
            <div className="flex flex-1 flex-col p-5 items-center">
              <img 
                  src={profile.frontmatter.profile_img || noImage} 
                  alt={`${profile.frontmatter.name} Profile`} 
                  className="mx-auto max-w-32 md:max-w-none w-32 h-32 md:h-52 md:w-52 shrink-0 rounded-xl" 
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = noImage;
                  }}
                />
              <h3 className="mt-6 text-sm font-medium text-white">{profile.frontmatter.name}</h3>
              <dl className="mt-1 flex grow flex-col justify-between text-center">
                <dt className="sr-only">Status</dt>
                <dd className="text-xs capitalize text-gray-400 overflow-hidden inline-block h-5">{profile.frontmatter.status}</dd>
              </dl>
            </div>
          </div>
        </Link>
      ))}
    </Marquee>
  );
};

export default ProfileMarquee;
