import * as React from "react";
import { Link, graphql } from "gatsby";
import HeroImg from "../images/slides/minhle17vn.jpg";
import ProfileMarquee from '../components/ProfileMarquee';
import Layout from "../components/layout";
import Seo from "../components/seo";
import ProductionSlides from '../components/productionSlides';
import CrewProfilesSlides from '../components/crewProfilesSlides';

const getRandomProfiles = (profiles, num) => {
  const shuffled = [...profiles].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const BlogIndex = ({ data, location }) => {
  const profileData = data.allMarkdownRemark.nodes;
  const randomLeadershipProfiles = getRandomProfiles(profileData, 8);
  const [randomProfile] = getRandomProfiles(profileData, 1);

  return (
    <Layout location={location}>
      <div className="bg-gray-800 text-white md:pb-20">
        <div className="p-5 md:py-10 md:px-0 container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            
            <div className="">
              <h1 className="text-4xl md:text-5xl mb-3 font-bold uppercase">
              Revolutionizing Production Hiring with AI Precision
              </h1>
              <h2 className="text-md md:text-xl font-semibold">
                CREWZIP – Instantly Generate Your Ideal Crew List with Just a Prompt
              </h2>
              <div className="md:py-5">
                <div className="md:w-3/4">
                CREWZIP leverages advanced AI to streamline your search for top production talent. Our technology curates a customized roster of crew members tailored to your project’s exact needs, delivering unmatched efficiency and accuracy.
                </div>
                <div className="my-5 md:my-0 md:w-7 relative float-right bottom-0 md:bottom-10 right-5">
                  <Link to="/profiles"><span className="text-3xl">→</span></Link>
                </div>
              </div>
            </div>

            <div className="">
              <Link to="/profiles"><img src={HeroImg} className="w-full rounded-lg shadow-lg" /></Link>
            </div>

          </div>
        </div>
        

        <div className="py-10">
          <ProfileMarquee profiles={profileData} />
        </div>

        <div className="md:pt-10">
          <ProductionSlides />
        </div>

        <div className="">
          <div className="bg-slate-800 py-24 sm:py-24">
            <div className="mx-auto grid max-w-7xl gap-5 md:gap-20 px-6 lg:px-8 xl:grid-cols-3">
              
              <div className="max-w-xl">
                <h2 className="text-pretty text-3xl font-semibold tracking-tight text-white sm:text-4xl">
                AI-Driven Custom Crew Selection – The CREWZIP Method
                </h2>
                <p className="mt-6 text-lg/8 text-white">
                CREWZIP uses Generative AI to quickly assemble a tailored list of crew members for your project, automating selection to meet your unique production needs. Effortlessly find the right talent and bring your vision to life with precision and speed.
                </p>
              </div>
              
              <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2 bg-slate-900 p-6 md:p-10 rounded-xl">
                {randomLeadershipProfiles.map((profile) => (
                  <li key={profile.frontmatter.name}>
                    <Link to={profile.fields.slug} className="block">
                      <div className="flex items-center gap-x-6">
                        <img alt="" src={profile.frontmatter.profile_img} className="h-24 w-24 rounded-md" />
                        <div>
                          <h3 className="text-base/7 font-semibold tracking-tight text-white">{profile.frontmatter.name}</h3>
                          <p className="text-sm/6 font-semibold text-gray-400">{profile.frontmatter.tagline}</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>

            </div>
          </div>
        </div>


        <div className="p-10 rounded-xl max-w-7xl mx-auto bg-gray-900 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
            <div className="">
              <CrewProfilesSlides />
            </div>
            <div className="flex flex-col justify-between h-full px-0 py-5 md:py-0 md:px-10" >
              <h1 className="text-4xl md:text-6xl font-bold mb-5 uppercase md:my-0">Find and Share your crew</h1>
              <p className="mb-5 md:text-lg md:leading-6">CREWZIP is a dynamic production job matching platform. <br /><br /> Connecting seamlessly with top premier talent and production opportunities, ensuring every project reaches its creative potential.</p>
              <div className="my-5 md:my-0"><Link to="/search"><button className="bg-gray-700 text-white focus:text-white py-4 w-full px-4 hover:bg-gray-600 text-md rounded-xl">Search Profiles</button></Link></div>
            </div>
          </div>
        </div>

        {/* <div className="md:py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0 ">
            <div className="">
              <Link to={randomProfile.fields.slug}>
                <img src={randomProfile.frontmatter.profile_img} alt={randomProfile.frontmatter.name} className="w-96" />
                <div>
                  <h3 className="text-base/7 font-semibold tracking-tight text-white">{randomProfile.frontmatter.name}</h3>
                  <p className="text-sm/6 font-semibold text-gray-400">{randomProfile.frontmatter.tagline}</p>
                </div>
              </Link>
            </div>
            <div className="flex flex-col justify-between bg-gray-900 text-white p-5 md:py-0 md:px-10  h-full" >
              <h1 className="text-4xl md:text-6xl font-bold mb-5 uppercase md:my-0">Find and Share your crew</h1>
              <p className="mb-5 md:text-lg md:leading-6">CREWZIP is a dynamic production job matching platform. <br /><br /> Connecting seamlessly with top premier talent and production opportunities, ensuring every project reaches its creative potential.</p>
              <div className="my-5 md:my-0"><Link to="/search"><button className=" bg-gray-700 border border-white bg-transparent text-white focus:text-white py-4 w-full px-4 hover:bg-gray-800 text-md">Search Profiles</button></Link></div>
            </div>
          </div>
        </div> */}

        
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const Head = () => <Seo title="Connecting seamlessly with top talent and production opportunities" />;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/profile/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 165)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          name
          description
          profile_img
          tagline
          pronoun
          status
          professional_professions
          professional_projects
          professional_genres
          social_links
        }
      }
    }
  }
`;
