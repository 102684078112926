// src/components/productionSlides.js
import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Slide2 from '../images/slides/0leCGAXvuRE.jpg';
import Slide3 from '../images/slides/FpWaEa2_d5w.jpg';
import Slide4 from '../images/slides/m4VWudGEzWM.jpg';

const ProductionSlides = () => {
  return (
    <Splide
      options={{
        type: 'loop',
        autoplay: true,
        interval: 3000,
        arrows: false,
        pagination: false,
      }}
      className="w-full"
    >
      <SplideSlide>
        <img src={Slide2} alt="Slide 2" className="w-full h-[600px] object-cover" />
      </SplideSlide>
      <SplideSlide>
        <img src={Slide3} alt="Slide 3" className="w-full h-[600px] object-cover" />
      </SplideSlide>
      <SplideSlide>
        <img src={Slide4} alt="Slide 4" className="w-full h-[600px] object-cover" />
      </SplideSlide>
    </Splide>
  );
};

export default ProductionSlides;
