// src/components/crewProfilesSlides.js
import React, { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

// Import images from src/images/crew-imgs
const images = Array.from({ length: 15 }, (_, i) => require(`../images/crew-imgs/${i + 1}.png`));

const CrewProfilesSlides = () => {
  const splideRef = useRef(null); // Reference to Splide instance

  // Shuffle images randomly
  const shuffledImages = images.sort(() => Math.random() - 0.5);

  const handleClick = () => {
    if (splideRef.current) {
      splideRef.current.go('>');
    }
  };

  return (
    <Splide
      options={{
        type: 'fade',
        autoplay: true,
        interval: 2000,
        arrows: false,
        pagination: false,
        loop: true,
        speed: 2000,
        pauseOnHover: false,
      }}
      ref={splideRef}
      className="w-full cursor-pointer"
      onClick={handleClick}
    >
      {shuffledImages.map((img, index) => (
        <SplideSlide key={index}>
          <img src={img.default} alt={`Crew member ${index + 1}`} className="w-full md:h-[500px] object-cover rounded-xl" />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default CrewProfilesSlides;
